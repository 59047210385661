<template>
  <div id="flightDetail" class="Content-Page">
    <CompPassengerOutboundMubadala
      ref="CompPassengerOutboundMubadala"
      :flightId="$route.params.id"
    ></CompPassengerOutboundMubadala>
  </div>
</template>

<script>
import fillerdate from "../utils/filterdate";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import CompPassengerOutboundMubadala from "../components/checkin-form/CompPassengerOutboundMubadala";

import { format, addHours } from "date-fns";
import dayjs from "dayjs";
import { saveComplete } from "@syncfusion/ej2-vue-grids";
export default {
  data: () => ({
    // ----------------------------------------------
  }),
  props: [],
  components: {
    CompPassengerOutboundMubadala,
  },
  async mounted() {
    this.b_id = await this.$route.params.id;

    try {
    } catch (error) {
      console.log("mounted ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error);
    }
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.mdi:before,
.mdi-set {
  color: #33cc33;
}
</style>
<style>
.v-messages__message {
  color: #b0afaf !important;
}
</style>